window.addEventListener("DOMContentLoaded", () => {
  const mainElem = document.querySelector("main");
  const nav = document.querySelector("nav");
  const footer = document.querySelector("footer");

  let navHeight = nav.offsetHeight;
  let footerHeight = footer.offsetHeight;
  let windowHeight = window.innerHeight;
  mainElem.style.minHeight = windowHeight - footerHeight + "px";

  window.addEventListener("resize", () => {
    navHeight = nav.offsetHeight;
    windowHeight = window.innerHeight;
    mainElem.style.minHeight = windowHeight - footerHeight + "px";
    // console.log(navHeight);
  });
});

// Über mich
window.addEventListener("DOMContentLoaded", () => {
  let menuOneBox = document.querySelector("#menu-1-title");
  let menuOneTitle = document.querySelector("#menu-1-title > p");
  let slideOne = document.querySelector("#slide-1");
  let menuTwoBox = document.querySelector("#menu-2-title");
  let menuTwoTitle = document.querySelector("#menu-2-title > p");
  let slideTwo = document.querySelector("#slide-2");
  let menuThreeBox = document.querySelector("#menu-3-title");
  let menuThreeTitle = document.querySelector("#menu-3-title > p");
  let slideThree = document.querySelector("#slide-3");

  if (menuOneBox) {
    // reset styles
    menuOneBox.style.backgroundColor = "#FF9B00";
    menuOneTitle.style.color = "#FFFFFF";
    slideOne.style.display = "block";
    slideTwo.style.display = "none";
    slideThree.style.display = "none";

    menuOneBox.addEventListener("click", () => {
      menuOneBox.style.backgroundColor = "#FF9B00";
      menuOneTitle.style.color = "#FFFFFF";

      menuTwoBox.style.backgroundColor = "#FFFFFF";
      menuTwoTitle.style.color = "#003C69";

      menuThreeBox.style.backgroundColor = "#FFFFFF";
      menuThreeTitle.style.color = "#003C69";

      slideOne.style.display = "block";
      slideTwo.style.display = "none";
      slideThree.style.display = "none";
    });

    menuTwoBox.addEventListener("click", () => {
      menuOneBox.style.backgroundColor = "#FFFFFF";
      menuOneTitle.style.color = "#003C69";

      menuTwoBox.style.backgroundColor = "#FF9B00";
      menuTwoTitle.style.color = "#FFFFFF";

      menuThreeBox.style.backgroundColor = "#FFFFFF";
      menuThreeTitle.style.color = "#003C69";

      slideOne.style.display = "none";
      slideTwo.style.display = "block";
      slideThree.style.display = "none";
    });

    menuThreeBox.addEventListener("click", () => {
      menuOneBox.style.backgroundColor = "#FFFFFF";
      menuOneTitle.style.color = "#003C69";

      menuTwoBox.style.backgroundColor = "#FFFFFF";
      menuTwoTitle.style.color = "#003C69";

      menuThreeBox.style.backgroundColor = "#FF9B00";
      menuThreeTitle.style.color = "#FFFFFF";

      slideOne.style.display = "none";
      slideTwo.style.display = "none";
      slideThree.style.display = "block";
    });
  }
});
