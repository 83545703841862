// console.log("WTF");
/* CSS */
import "@js/parts/css.js";

/* Site */
import "@js/parts/site.js";

/* JS */
import "@js/parts/lazyloading.js";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
window.Alpine = Alpine;
Alpine.plugin(intersect)
Alpine.start();



// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
